<template>
  <div class="my_order">
    <div class="my_order_header">
      <!-- <div class="item">
        <span class="title">产品名称</span>
        <el-select class="my_order_select" v-model="value" clearable placeholder="请选择">
          <el-option v-for="item in options" :key="item.prodName" :label="item.prodName" :value="item.prodName">
          </el-option>
        </el-select>
      </div>-->
      <div class="item">
        <span class="title">时间范围</span>
        <el-date-picker
          class="my_order_date"
          v-model="beginTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="开始时间"
        ></el-date-picker>
        <span class="to">-</span>
        <el-date-picker
          class="my_order_date"
          v-model="endTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="结束时间"
        ></el-date-picker>
      </div>
      <div class="item">
        <el-button class="my_order_query_btn" @click="queryEvent">查询</el-button>
      </div>
    </div>
    <div class="my_parent_main ">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="createTime" label="检索时间" align="center"></el-table-column>
        <el-table-column prop="productName" label="产品名称" align="center"></el-table-column>
        <el-table-column prop="initiateType" label="发起类型" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.initiateType == 'web' ? '网站' : scope.row.initiateType == 'api' ? '接口' :''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="240">
          <template slot-scope="scope">
            <i class="order_upload" @click="uploadEvent(scope.row.fileUrl)">
              <img src="@/assets/images/common/download_hover.png" alt />
            </i>
          </template>
        </el-table-column>
      </el-table>
      <div class="el_pagination_wrap">
        <el-pagination
          v-if="tableData.length>0"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page="current"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [],
      value: "",
      beginTime: "",
      endTime: "",
      tableData: [],
      current: 1,
      size: 10,
      total: 0
    };
  },
  mounted() {
    // this.productListAjax();
    this.queryAjax();
  },
  methods: {
    //   产品列表信息
    async productListAjax() {
      let data = await this.$axios.get(
        "/product/servicemanage/getOwnerServiceManageProdList"
      );
      if (data.data.code == 0) {
        this.options = data.data.data;
      }
    },
    queryEvent() {
      this.queryAjax();
    },
    async queryAjax() {
      let data = await this.$axios.get("/lantern/lanlog/page", {
        params: {
          productType: "报告",
          current: this.current,
          size: this.size,
          startTime: this.beginTime,
          endTime: this.endTime,
          productName: this.value
        }
      });
      if (data.data.code == 0) {
        this.tableData = data.data.data.records;
        this.total = data.data.data.total;
      }
    },
    async uploadEvent(fileUrlParam) {
      let data = await this.$axios.get(
        "/common/file",
        {
          responseType: "blob", // 表明返回服务器返回的数据类型, 没有会乱码

          params: {fileUrl: fileUrlParam} // 里面是请求参数
        }
      );
      let blob = new Blob([data.data], {
        type: "application/msword" //word文档为msword,pdf文档为pdf
      });
      let objectUrl = URL.createObjectURL(blob);
      let link = document.createElement("a");
      let fname = `贯标分析报告.docx`; //下载文件的名字
      link.href = objectUrl;
      link.setAttribute("download", fname);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    toTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    handleCurrentChange(val) {
      this.current = val;
      this.toTop();
      this.queryAjax();
    },
    handleSizeChange(val) {
      this.size = val;
      this.toTop();
      this.queryAjax();
    }
  }
};
</script>
<style lang="less" scoped>
@blue: #2f56de;
.my_order_header {
  height: 85px;
  line-height: 85px;
  padding: 0 25px;
  background: #fff;
  box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
  margin-bottom: 8px;
  font-size: 14px;
  .item {
    display: inline-block;
    .title {
      margin-right: 20px;
    }
    .to {
      color: #c2c6d0;
    }
  }
}
.my_parent_main {
  padding: 32px 10px;
  background: #fff;
  box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
  min-height: calc(100vh - 355px);
  .order_upload {
    display: inline-block;
    width: 20px;
    vertical-align: middle;
    cursor: pointer;
    img {
      width: 100%;
      display: block;
    }
  }
  .el_pagination_wrap {
    text-align: center;
    margin-top: 10px;
  }
}
</style>
<style lang="less">
.my_order_select {
  margin-right: 27px;
  line-height: 32px;
}
.my_order_select.el-input {
  width: 250px;
}
.my_order_select .el-input__inner {
  width: 250px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #c2c6d0;
  border-radius: 5px;
  color: #666666;
}
.my_order_select .el-input__prefix,
.my_order_select .el-input__suffix {
  height: inherit;
}
.my_order_date.el-input {
  width: 160px;
}
.my_order_date .el-input__inner {
  width: 160px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #c2c6d0;
  border-radius: 5px;
  color: #666666;
}
.my_order_query_btn {
  width: 78px;
  height: 34px;
  line-height: 34px;
  background-color: @blue;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  padding: 0;
  margin-left: 27px;
  border: none;
  &:focus,
  &:hover {
    border: none;
    color: #fff;
    background-color: @blue;
  }
  &:hover {
    box-shadow: @boxShadow;
  }
}
</style>
